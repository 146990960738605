import Vue from 'vue'

const isDebugMode = process.env.NODE_ENV !== "production";
Vue.config.debug = isDebugMode;
Vue.config.devtools = isDebugMode;
Vue.config.productionTip = isDebugMode;

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import '@/styles/index.less' // global css
import App from './App'
import router from './router'
import store from './store'
import '@/icons' // icon

import './assets/iconfont/iconfont.css' // iconfont.cn 网站在线链接和生产代码功能无法使用,每添加一个icon都要下载一次替换调

import './permission' // permission control
import i18n from './lang' // Internationalization
import publicPagination from '@/components/public/publicPagination'

import channelDesc from '@/components/finance/depositAction/channelDesc'

import centTab from '@/components/public/centTab'
import switchLanguages  from '@/components/public/switchlanguage'
Vue.component('switchLanguages',switchLanguages);
Vue.component('centTab',centTab);

Vue.component('channelDesc',channelDesc);

// 全局注册组件
Vue.component('publicPagination',publicPagination);

import uploadImgTips from '@/components/public/uploadImgTips'
Vue.component('uploadImgTips',uploadImgTips);

import { subsctringFileName } from '@/utils/index'
import translate from 'i18n-jsautotranslate'
Vue.prototype.$subsctringFileName = subsctringFileName;

Vue.use(ElementUI, {
  locale:locale,
  i18n: (key, value) => i18n.t(key, value)
 });

//  quill富文本编辑器 编辑出来的文章详情的样式
import './styles/quill_base.css'
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})