import router from './router'
import store from './store'
import { getLoginPageSiteName } from '@/utils/index'
import { buildMenus } from '@/api/system/menu'
import { filterAsyncRouter } from './store/modules/permission'
import translate from 'i18n-jsautotranslate'

const whiteList = ['/login','/resetPass']// no redirect whitelist
let transtimer;
let transcount = 0;
function customtrans(){
  var currentlang = translate.language.getCurrent();
  console.log("当前使用的是"+currentlang)
  // 获取所有具有指定class的元素
  var buttons = document.querySelectorAll('.cell .el-button');
  // 遍历这些元素
  buttons.forEach(function(button) {
      // 在该元素下查找所有的span元素
      var spans = button.getElementsByTagName('span');
      // 遍历并处理找到的span元素
      for (var i = 0; i < spans.length; i++) {
        const translations = {
          english: {
              "编辑": "edit",
              "删除": "delete",
              "生成代码": "Generated code",
              "执行": "Carry out",
              "暂停": "Pause",
              "恢复": "recover",
              "查看": "view",
              "客服查看": "Customer service view",
              "审核": "examine",
              "更多操作": "More",
              "出入金": "Receipts and receipts",
              "状态调整": "State adjustment",
              "支付状态调整": "Payment status adjustment",
              "禁用": "forbidden",
              "启用": "enable",
              "重置密码": "Reset password"
          },
          vietnamese: {
            "编辑": "Sửa",
            "删除": "Xóa",
            "生成代码": "Tạo mã",
            "执行": "Thực hiện",
            "暂停": "Dừng",
            "恢复": "Khôi phục",
            "查看": "Xem",
            "客服查看": "Xem của bộ phận chăm sóc khách hàng",
            "审核": "Xem xét",
            "更多操作": "Nhiều thao tác hơn",
            "出入金": "Nhập và rút tiền",
            "状态调整": "Điều chỉnh trạng thái",
            "支付状态调整": "Điều chỉnh tình trạng thanh toán",
            "禁用": "Không thể sử dụng",
            "启用": "Bật",
            "重置密码": "Đặt lại mật khẩu"
          },
          chinese_traditional:{
            "编辑": "編輯",
            "删除": "刪除",
            "生成代码": "產生代碼",
            "执行": "執行",
            "暂停": "暫停",
            "恢复": "恢復",
            "查看": "查看",
            "客服查看": "客服查看",
            "审核": "審核",
            "更多操作": "更多操作",
            "出入金": "出入金",
            "状态调整": "狀態調整",
            "支付状态调整": "支付狀態調整",
            "禁用": "禁用",
            "启用": "啟用",
            "重置密码": "重置密碼"
        }
          // 可以在这里添加其他语言的翻译映射
      };
      
      function translateText(text, lang) {
          if (translations[lang] && translations[lang][text]) {
              return translations[lang][text];
          }
          return text; // 如果找不到翻译，返回原文本
      }
      
      if (currentlang != "chinese_simplified") {
        for (let i = 0; i < spans.length; i++) {
          spans[i].innerText = translateText(spans[i].innerText.trim(), currentlang);
        }
      }
      }
  });
}
function translang(){
    clearTimeout(transtimer);
    transtimer = setTimeout(() => {
      transcount++;
      translate.service.use('client.edge')
      translate.progress.api.startUITip();
      translate.execute();
      customtrans();             
  }, 3000);
}
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    if(getLoginPageSiteName()){
      document.title = to.meta.title + ' -  ' + getLoginPageSiteName();
    }else {
      document.title = to.meta.title;
    }
  }
  
 
  if (localStorage.getItem('token')) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      // next({ path: '/login' })
      next();
    } else {
      if (store.getters.roles.length === 0) { // 判断当前用户是否已拉取完user_info信息

        store.dispatch('GetInfo').then(res => { // 拉取user_info
          // 动态路由，拉取菜单
          loadMenus(next, to)
        }).catch((err) => {
          store.dispatch('LogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
      // 登录时未拉取 菜单，在此处拉取
      } else if (store.getters.loadMenus) {
        // 修改成false，防止死循环
        store.dispatch('updateLoadMenus').then(res => {})
        loadMenus(next, to)
      } else {
        next()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
    }
  }
})

export const loadMenus = (next, to) => {
  buildMenus().then(res => {
    const asyncRouter = filterAsyncRouter(res)
    asyncRouter.push({ path: '*', redirect: '/404', hidden: true });
    store.commit('SET_ROUTERS',asyncRouter);
    /*store.dispatch('GenerateRoutes', asyncRouter).then(() => { // 存储路由 //有点问题
      router.addRoutes(asyncRouter) // 动态添加可访问路由表
      next({ ...to, replace: true })// hack方法 确保addRoutes已完成
    });*/
    router.addRoutes(asyncRouter) // 动态添加可访问路由表
    next({ ...to, replace: true })// hack方法 确保addRoutes已完成
  })
}

router.afterEach((to,from) => {
  if(to.name != 'permission'){
    translang();
  }else{
    //如果是permission  页面
    
  }
})
