<template>
    <div>
      <el-col  class="switchLanguages">
      <!-- <span class="demonstration">多语言</span> -->
      <el-dropdown  @command="changeLanguage" trigger="click">
        <span class="el-dropdown-link">
          <div class="flagIcon">
          <img style="width: 18px;height: 14px;margin-top: -2px;" v-show="currentLanguage == 'english'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAA1CAMAAACA7r40AAAA/FBMVEWyIjNPN2Y8O27///8/PnBOTXxycpY9PG9RUX5HR3dIR3dLSnlPTny4uMpWVYFFRHVwb5VlZIx7e52DgqOIh6aIiKc+PXBJSHhTUn9hYYqyIjReXohgX4lYV4Lr6/C2tcjPdYB0c5haWYRYV4Nra5GJiac+PW/FxdTHYG1RUH3eoajltbtOTXvty89BQHHg3+hSUX6GYoC6usyVlbFycZa7u8yUk6+sq8FCQXO0tMe1tMhiYYpMS3pDQnNvbpTExNN8fJ7GxdTGxtWCgqLHx9WXlrLXi5SYl7Kiobrg4Oiiorrn5u3n5+2jo7ukpLvw8PTx8fX09Pf19firqsC5sMNxAAAAAnRSTlPQ9qN1Xw4AAAGlSURBVHhe7ZXFiiRBFEVjznvp5W7t7j3u7m7//y8D1dAMGbnI4jW5KOpsgjibCxFcrrtVEmcBGAGQJHB98d3EgEM/ygsg/nwRAy15rxQ4Ywh3JQX49hVgKA/Ad1hDkkGTMSQJjGkOEmo133FmwAEQyl8FfSj3gFgkzjsQAw6AZ796AP2fGcDBS8/ZQ7ZQlJ2d+bEFqp6zhuh9Cf9/pDtKkTs04KBTD4jgx3eICOodoshz1p7M6UUAUQ+Ap2Pf2UMI5UBBX18/Ut7ZQ84Jui10QKsbcA7dt77jxIBDt+sxMNzrD4HL+rZS4Iw9iTZkCjRFmsBUHkcUOGMI7dkpKezuQsrprM3zR76zN572ekdBX623gaONJ0d5B5sGHAA1+QTwRQJgX2Q/7+w9GZGlDZKERpoxgkbDc8s0WscGSo+WGMiNVlgwWuFNjdafPsDe7wzg3RvP2UNCFGVtbX6EoOo7PhgoO1rGnswHiquB4mqgfIcxpBTGkNslMf2JVMAShRxXgJtUwBKFbFbAqicL4U4qYNWThXCHFbDqyUK4swpY9WQhXBX8A2rVjaQ6HWXmAAAAAElFTkSuQmCC" alt="en_US" class="lang-icon"/>
          <img style="width: 18px;height: 14px;margin-top: -2px;" v-show="currentLanguage == 'chinese_simplified'||currentLanguage == 'chinese_traditional'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAgCAYAAABU1PscAAACOUlEQVRYR+3YvWtTURgG8N+9TWpqEYo4KFRBHBwsCGLBzYJIcdaliCAOav8AFTfroOCiawd1EBQEJwd1U3AQHMSpiIuD2q2TtvloEjlJA9Gk6SUhuRXyLhcu5+N53uc57zm8EVSZzHOvygz2hX/bOJYj3ua4HvE9CuDX+Izd3YCuIupmYu9zVsY4Gq3xtMpcN+uNTBPvpfSym9m9z4l4Fq3ys6NtRlFs3SxkfucjHKFwmUrQcPCxHAgELG0jPsSOC6zearVJsE3mDPEBCov16WnYqYVAg0345uYYuUb+GJUNis1+bwYcHyaaoPwVK4OTooVA9iRGqf4iO4/TVO9Q/oRdlL9RWfobYM1OCzhH8SLljykSGD3LyA1Mbng/oIuDX/COwgKVL60As7NkjlN4TiWoMKBoa6F4P7n7OIH1DSSLrN1Nx+edctH2EAe/jz/AefxGltIlSm9Sq/mbcmhfhfYw9hrvKb0iexNLrM5vTiBzisw0xSdUfgzIP+ES/beMBstnZsgcJP+4DiQaJ3eVfLBVoxw1Yawd4tv1QxyUWv+QIoHG1klqeqPkhtIaTxFPsB6qVbDdgKLjRbYVhsws8STFh9voItsKdLNCtafEFIUrVELmU4ieFAiPuUx4SrxIAXnjZdDpLZQerOQ796RA8m36N3JIoH+5TbbyUIFkeerfqKEC/cttspWHCiTLU/9GDRXoX26Trbx1YyvZOmmNWu6ptZgW6sa+tdZir83dFEnUm7sBwP/cXv8D3R2zQXfaQqgAAAAASUVORK5CYII=" alt="fr" class="h-4 w-5 mr-1"/>
          <img style="width: 18px;height: 14px;margin-top: -2px;" v-show="currentLanguage == 'vietnamese'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAgCAYAAABU1PscAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKqSURBVHgB7VjdahNBFP5md9OkSTeJXX+KbTRJrV4peKMt6J2CoCAKglc+gYriWwg+gXhj38JXEBShLf5FUKyNNZG05n9ndzyzqdG2oZXNDGkhHyQXs7Mz58z3nfMNy97PZAT2MQzscwwTGDSGCewE4dOfD62woBFGTCBocS4DNPU6bQwIDiRv1JG6WYOQCWiCtgTcVQtJCt6+Xoe7oo9obSszWjl2thXUAIuQfiQJGmSkhQHhkXyu1ro72NdIRlyPjLQkwH9YcO6vdU6cfs7ddfDvJnQgvIQodb/KwH+aYFuOgXsmRs+3upKJzzbBOSXwbcsa9NxIezBtEbrdhk7AK5uYeFJC+k4VosW2c0kywp9Dp+BOVwuba0CWxYhA5bmN4iMH5gEPYcBC30YpYL5qYiTvYvrlcmBaARO7SZ12E3JHn6EwexRuIQLriBeagfA1QBtaBz14FQMLkTyar6Kd4Hc6SPmM5jRfR7GQyMOnd61DXl9u3XcRy1OPnnRRuDiF4gOnI5tenMog6VnxoYOPc1OITbe31U4YqOlC5LrRbBulpylU5u3eMqKdKvNjKD9LIZZrB++ogLo2KmuAgkrdrvZmgMZSt8gb2lBqaOoSoKASFxpd15VmJposMDCxoX1Glzs5Z08mwEsmxqV5bUiDf7GwdDiLpfEc3E+RziCx5NxbAy+rMzVlCfi/DNiXGoGzlB6n8PbEcYxMckQnXbw7dYzG0sFuicsNeFV1xDMlXyVohUiOI/tiBR/OZMCXZXv0/7bHwDNoLONh5s1XfL4ygTb1fyi4Hik5Cr/GEJ9rYpF6u5AkOP7m3h54hg9RZ1iM5zF6rkXvqGFBDQNGR0KG7e9uSnLuOs1N+n0Z2D/LKQAFYiT+MyA5d0xN8BLDrxKDxjCBQWOYwKDxG0GH6YmYKpIUAAAAAElFTkSuQmCC" alt="fr" class="h-4 w-5 mr-1"/>
        </div>
          {{ currentLanguageName }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in languages" :command="item.value">
            <img style="width: 18px;height: 14px;margin-top: -2px;" v-if="item.value == 'english'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAA1CAMAAACA7r40AAAA/FBMVEWyIjNPN2Y8O27///8/PnBOTXxycpY9PG9RUX5HR3dIR3dLSnlPTny4uMpWVYFFRHVwb5VlZIx7e52DgqOIh6aIiKc+PXBJSHhTUn9hYYqyIjReXohgX4lYV4Lr6/C2tcjPdYB0c5haWYRYV4Nra5GJiac+PW/FxdTHYG1RUH3eoajltbtOTXvty89BQHHg3+hSUX6GYoC6usyVlbFycZa7u8yUk6+sq8FCQXO0tMe1tMhiYYpMS3pDQnNvbpTExNN8fJ7GxdTGxtWCgqLHx9WXlrLXi5SYl7Kiobrg4Oiiorrn5u3n5+2jo7ukpLvw8PTx8fX09Pf19firqsC5sMNxAAAAAnRSTlPQ9qN1Xw4AAAGlSURBVHhe7ZXFiiRBFEVjznvp5W7t7j3u7m7//y8D1dAMGbnI4jW5KOpsgjibCxFcrrtVEmcBGAGQJHB98d3EgEM/ygsg/nwRAy15rxQ4Ywh3JQX49hVgKA/Ad1hDkkGTMSQJjGkOEmo133FmwAEQyl8FfSj3gFgkzjsQAw6AZ796AP2fGcDBS8/ZQ7ZQlJ2d+bEFqp6zhuh9Cf9/pDtKkTs04KBTD4jgx3eICOodoshz1p7M6UUAUQ+Ap2Pf2UMI5UBBX18/Ut7ZQ84Jui10QKsbcA7dt77jxIBDt+sxMNzrD4HL+rZS4Iw9iTZkCjRFmsBUHkcUOGMI7dkpKezuQsrprM3zR76zN572ekdBX623gaONJ0d5B5sGHAA1+QTwRQJgX2Q/7+w9GZGlDZKERpoxgkbDc8s0WscGSo+WGMiNVlgwWuFNjdafPsDe7wzg3RvP2UNCFGVtbX6EoOo7PhgoO1rGnswHiquB4mqgfIcxpBTGkNslMf2JVMAShRxXgJtUwBKFbFbAqicL4U4qYNWThXCHFbDqyUK4swpY9WQhXBX8A2rVjaQ6HWXmAAAAAElFTkSuQmCC" alt="en_US" class="lang-icon">
            <img style="width: 18px;height: 14px;margin-top: -2px;" v-if="item.value == 'chinese_simplified'||item.value == 'chinese_traditional'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAgCAYAAABU1PscAAACOUlEQVRYR+3YvWtTURgG8N+9TWpqEYo4KFRBHBwsCGLBzYJIcdaliCAOav8AFTfroOCiawd1EBQEJwd1U3AQHMSpiIuD2q2TtvloEjlJA9Gk6SUhuRXyLhcu5+N53uc57zm8EVSZzHOvygz2hX/bOJYj3ua4HvE9CuDX+Izd3YCuIupmYu9zVsY4Gq3xtMpcN+uNTBPvpfSym9m9z4l4Fq3ys6NtRlFs3SxkfucjHKFwmUrQcPCxHAgELG0jPsSOC6zearVJsE3mDPEBCov16WnYqYVAg0345uYYuUb+GJUNis1+bwYcHyaaoPwVK4OTooVA9iRGqf4iO4/TVO9Q/oRdlL9RWfobYM1OCzhH8SLljykSGD3LyA1Mbng/oIuDX/COwgKVL60As7NkjlN4TiWoMKBoa6F4P7n7OIH1DSSLrN1Nx+edctH2EAe/jz/AefxGltIlSm9Sq/mbcmhfhfYw9hrvKb0iexNLrM5vTiBzisw0xSdUfgzIP+ES/beMBstnZsgcJP+4DiQaJ3eVfLBVoxw1Yawd4tv1QxyUWv+QIoHG1klqeqPkhtIaTxFPsB6qVbDdgKLjRbYVhsws8STFh9voItsKdLNCtafEFIUrVELmU4ieFAiPuUx4SrxIAXnjZdDpLZQerOQ796RA8m36N3JIoH+5TbbyUIFkeerfqKEC/cttspWHCiTLU/9GDRXoX26Trbx1YyvZOmmNWu6ptZgW6sa+tdZir83dFEnUm7sBwP/cXv8D3R2zQXfaQqgAAAAASUVORK5CYII=" alt="fr" class="h-4 w-5 mr-1">
            <img style="width: 18px;height: 14px;margin-top: -2px;" v-if="item.value == 'vietnamese'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAgCAYAAABU1PscAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKqSURBVHgB7VjdahNBFP5md9OkSTeJXX+KbTRJrV4peKMt6J2CoCAKglc+gYriWwg+gXhj38JXEBShLf5FUKyNNZG05n9ndzyzqdG2oZXNDGkhHyQXs7Mz58z3nfMNy97PZAT2MQzscwwTGDSGCewE4dOfD62woBFGTCBocS4DNPU6bQwIDiRv1JG6WYOQCWiCtgTcVQtJCt6+Xoe7oo9obSszWjl2thXUAIuQfiQJGmSkhQHhkXyu1ro72NdIRlyPjLQkwH9YcO6vdU6cfs7ddfDvJnQgvIQodb/KwH+aYFuOgXsmRs+3upKJzzbBOSXwbcsa9NxIezBtEbrdhk7AK5uYeFJC+k4VosW2c0kywp9Dp+BOVwuba0CWxYhA5bmN4iMH5gEPYcBC30YpYL5qYiTvYvrlcmBaARO7SZ12E3JHn6EwexRuIQLriBeagfA1QBtaBz14FQMLkTyar6Kd4Hc6SPmM5jRfR7GQyMOnd61DXl9u3XcRy1OPnnRRuDiF4gOnI5tenMog6VnxoYOPc1OITbe31U4YqOlC5LrRbBulpylU5u3eMqKdKvNjKD9LIZZrB++ogLo2KmuAgkrdrvZmgMZSt8gb2lBqaOoSoKASFxpd15VmJposMDCxoX1Glzs5Z08mwEsmxqV5bUiDf7GwdDiLpfEc3E+RziCx5NxbAy+rMzVlCfi/DNiXGoGzlB6n8PbEcYxMckQnXbw7dYzG0sFuicsNeFV1xDMlXyVohUiOI/tiBR/OZMCXZXv0/7bHwDNoLONh5s1XfL4ygTb1fyi4Hik5Cr/GEJ9rYpF6u5AkOP7m3h54hg9RZ1iM5zF6rkXvqGFBDQNGR0KG7e9uSnLuOs1N+n0Z2D/LKQAFYiT+MyA5d0xN8BLDrxKDxjCBQWOYwKDxG0GH6YmYKpIUAAAAAElFTkSuQmCC" alt="fr" class="h-4 w-5 mr-1">
            
            {{item.label}}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-col>
    </div>
  </template>
  
  <script>
  import translate from 'i18n-jsautotranslate'
  export default {
    name: "switchlanguage",
    data() {
      return {
        languages: [
          { label: '简体中文', value: 'chinese_simplified' },
          { label: 'English', value: 'english' },
          { label: '繁體中文', value: 'chinese_traditional' },
          // { label: 'Việt nam', value: 'vietnamese' }
        ],
        localLanguage: translate.language.getLocal(),
        currentLanguage: '',
        currentLanguageName:"简体中文"
      }
    },
    mounted(){
      let _self = this;
      translate.language.setLocal('chinese_simplified')
      translate.service.use('client.edge')
      translate.ignore.class.push('permissioncontent');
      translate.ignore.tag.push('td');
      translate.listener.start();
      //translate.request.listener.start();
      translate.progress.api.startUITip();
      translate.execute();
      this.currentLanguage = translate.language.getCurrent();
      this.currentLanguageName =  this.languages.filter(function(obj){return obj.value == _self.currentLanguage})[0].label;
    },
    methods: {
      changeLanguage(lang) {
        this.currentLanguageName =  this.languages.filter(function(obj){return obj.value == lang})[0].label;
        translate.changeLanguage(lang)
        this.currentLanguage = translate.language.getCurrent()
      }
    }
  };
  </script>
 
  <style rel="stylesheet/less" lang="less" type="text/less" scoped>
  .switchLanguages{
    float: right;
    top: 8px;
    right: 8px;
    img{
    width: 20px;
    }
  }
  .flagIcon{
    width: 30px;
    float: left;
  }
  .flagIconItem{
    width: 18px;
    height: 14px;
  }
  </style>
  